import React, { Component } from "react"
import { connect } from "react-redux"

import "../components/layout.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Card from "../components/card"

class Sources extends Component {
  render() {
    return (
      <Layout location={this.props.location} crumbLabel="Sources">
        <SEO title="Sources" description="Sources for IB Vine." />
        <div className="page page-saved page-sources">
          <div className="header-1">Disclaimer</div>
          <p className="content content-sources">
            IB Vine is a free resource created with the intention of educating
            others. Questions are crowdsourced from 3rd-party, publicly
            available sources. IB Vine does not claim ownership or accuracy of
            questions.
          </p>
          <p className="content content-sources">
            Copyright Disclaimer under section 107 of the Copyright Act 1976:
            allowance is made for “fair use” for purposes such as teaching and
            education.
          </p>
          <p className="content content-sources">
            For any inquiries, email ibvine-support@umich.edu.
          </p>
        </div>
      </Layout>
    )
  }
}

export default connect(null, {})(Sources)
